import { stringify, parse } from 'qs'
import { ActionTree } from 'vuex'
import { OpenIDConnectScheme } from '@nuxtjs/auth-next'
import { IRootState } from '~/store'
import { IUserState } from '~/store/user/state'
import Storage from '~/lib/storage'
import getPayload from '~/lib/jwt/getPayload'
import internalLink from '~/lib/url/internalLink'
import { ITokenResponse } from '~/carrier/user/UserCarrier'
import { setLocale } from '~/plugins/i18n/persistLocale'

let tokenTimeout: number

const actions: ActionTree<IUserState, IRootState> = {
  async refreshAccessToken({ dispatch }) {
    if (this.$auth.strategy.name === 'sso') return this.$auth.refreshTokens()

    const refreshToken = Storage.getItem('user/refresh_token')
    if (!refreshToken) {
      throw new Error('refreshToken is not set')
    }

    try {
      const response = await this.$api.user.refreshAccessToken(refreshToken)
      if (!response) {
        throw new Error('authorize api failed')
      }
      await dispatch('setToken', response.accessToken)
      if (response.refreshToken) {
        // set the refreshToken only if it is there
        await dispatch('setRefreshToken', response.refreshToken)
      }
      await dispatch('verifyStrategy', response)
      await dispatch('loadSources')
    } catch (e: any) {
      dispatch('redirectToConfirm', e?.response)
      throw new Error('refresh access token failed')
    }
  },

  // TODO: remove after all users have transitioned
  async verifyStrategy(_, response: ITokenResponse): Promise<void> {
    const { accessToken, refreshToken, idToken } = response

    // must have all 3 types of tokens
    if (!accessToken || !refreshToken || !idToken) return

    // should match SSO provider
    const tokenData: any = getPayload(accessToken)
    if (!tokenData.iss.includes('sso')) return

    // switch auth strategy and set tokens
    this.$auth.setStrategy('sso')
    await this.$auth.setUserToken(accessToken, refreshToken)
    ;(this.$auth.strategy as OpenIDConnectScheme).idToken.set(idToken)
  },

  async login({ dispatch }, params) {
    // check state
    const loginStateKey = 'login/state'
    const loginState = Storage.getItem(loginStateKey)

    if (loginState && loginState !== params.state) {
      throw new Error('invalid state')
    }
    Storage.removeItem(loginStateKey)
    // clear refresh token
    Storage.removeItem('user/refresh_token')

    const response = await this.$api.user.getAccessToken(params.code)
    if (response === false) {
      throw new Error('response error')
    } else {
      const res: ITokenResponse = response as ITokenResponse
      if (res.accessToken) {
        dispatch('setToken', res.accessToken)
      }
      if (res.refreshToken) {
        dispatch('setRefreshToken', res.refreshToken)
      }
      await dispatch('loadSources')
    }

    this.$platform.native.notifications?.setup()
  },

  async load({ commit }) {
    try {
      const response = await this.$api.user.get()
      const { data } = response
      const {
        first_name: firstName,
        last_name: lastName,
        name,
        email,
        id
      } = data
      commit('setProfile', { firstName, lastName, name, email, id })

      if (data.language) {
        setLocale(this.$i18n, data.language)
      } else {
        await this.$api.user.put({ language: this.$i18n.locale })
      }

      const { rorg, role, root_org: rootOrg, access_type: accessType } = data
      const { type: rootOrgType } = rootOrg
      this.$auth.ctx.$mixpanel?.identifyUser(id, {
        RootOrg: rorg,
        RootOrgType: rootOrgType,
        Role: role,
        UserId: id
      })

      commit('rorg', rorg)
      commit('role', role)
      commit('rootOrgType', rootOrgType)
      commit('accessType', accessType)
    } catch (e) {}
  },

  async loadSources({ dispatch, commit }) {
    commit('setLoadingSources', true)
    // Promise all will immediately call the catch if a single promise get rejected (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/all#Promise.all_fail-fast_behaviour)
    // so we need to catch every single promise in error cases to avoid an early call of setLoadingSources commit.
    await dispatch('load', null)
    await dispatch('organization/reload', null, { root: true })
    commit('setLoadingSources', false)
  },

  async logout({ getters, commit, dispatch }) {
    const FCMToken = this.$platform.native.notifications?.token
    const refreshToken = Storage.getItem('user/refresh_token')

    const loggingOutMutex = getters.loggingOutMutex

    // prevents logout API from being called multiple times
    if (!loggingOutMutex) {
      commit('loggingOutMutex', true)
      commit('setLoadingSources', true)
      // call logout endpoint, continue even if it fails
      try {
        await this.$api.user.logout({
          fcm_token: FCMToken,
          refresh_token: refreshToken
        })
      } catch (e) {}

      // clear user data
      dispatch('clear')
      // clear all existing locations
      dispatch('organization/clear', null, { root: true })
      sessionStorage.clear()

      this.$auth.ctx.$mixpanel?.resetUser()
      this.$auth.logout()
      commit('setLoadingSources', false)
      // release the lock after it is done
      commit('loggingOutMutex', false)
    }
  },

  clear({ commit }) {
    commit('resetToken')
    commit('eula', false)
    clearTimeout(tokenTimeout)

    // remove from all used storages
    Storage.removeItem('user/refresh_token')
    Storage.removeItem('user/eula')
  },

  setEula({ commit }, eula) {
    const isTrueSet = eula === 'true' || eula === true
    commit('eula', isTrueSet)
    Storage.setItem('user/eula', isTrueSet)
  },

  async remove({ dispatch }) {
    try {
      const { status } = await this.$api.user.delete()

      if (status !== 200) {
        return dispatch(
          'notify/error',
          this.$i18n.tc('user.profile.remove_submit_error'),
          { root: true }
        )
      }

      dispatch('logout')
      dispatch(
        'notify/success',
        this.$i18n.tc('user.profile.remove_submit_success'),
        { root: true }
      )
    } catch {
      dispatch(
        'notify/error',
        this.$i18n.tc('user.profile.remove_submit_error'),
        { root: true }
      )
    }
  },

  redirectToConfirm(_, response) {
    if (response && response.status === 303) {
      const regex = /^([^?]+)\?(.+)$/gm
      const urlParts = regex.exec(response.headers['x-location'])
      if (urlParts && urlParts.length > 1) {
        const uri =
          this.$services.login.baseUrl +
          '/' +
          urlParts[1] +
          '?' +
          stringify({
            ...parse(urlParts[2]),
            redirect_uri: window.location.href,
            brand: this.app.config.branding
          })
        internalLink(uri, '_self')
      } else {
        throw new Error('invalid redirect')
      }
    }
  },

  setToken({ commit, dispatch }, token) {
    // if token is set, check for expiry, and try login with refresh token if token is expired
    const tokenData: any = getPayload(token)
    // Put in a bit of randomness to ensure Tokens do not all try to refresh at the same time
    // This also ensure the Tokens are definitely refreshed before it expires.
    const jitter = 60 + Math.floor(Math.random() * 20)
    // Using iat instead of Now() is fine since we always Refresh Tokens when app starts
    let expTime = (tokenData.exp - tokenData.iat - jitter) * 1000
    // eslint-disable-next-line unicorn/number-literal-case
    if (expTime > 0x7fffffff) {
      // eslint-disable-next-line unicorn/number-literal-case
      expTime = 0x7fffffff
    }
    clearTimeout(tokenTimeout)
    tokenTimeout = window.setTimeout(() => {
      dispatch('refreshAccessToken').catch(() => {
        dispatch('logout')
      })
    }, expTime)
    commit('login', token)
  },

  // required for LC login flow
  async updateRefreshToken({ dispatch }, refreshToken) {
    await dispatch('setRefreshToken', refreshToken)
    return dispatch('refreshAccessToken')
  },

  setRefreshToken(_, refreshToken) {
    Storage.setItem('user/refresh_token', refreshToken || '')
  },
  updateProfile({ commit }, { firstName, lastName, name, email }) {
    commit('setProfile', { firstName, lastName, name, email })
  }
}

export default actions
