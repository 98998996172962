import {
  Filesystem,
  Directory,
  ReaddirResult,
  GetUriResult,
  StatResult,
  FileInfo
} from '@capacitor/filesystem'
import { IFileStorage } from '~/plugins/platform/native/api/file-storage/IFileStorage'

const encodeForFilepath = (str: string): string => {
  return encodeURIComponent(str)
    .replace(/['()]/g, '_')
    .replace(/\*/g, '_')
    .replace(/%(?:7C|60|5E)/g, '_')
}

export class FileStorageApi implements IFileStorage {
  async load(path: string): Promise<string> {
    try {
      const { data } = await Filesystem.readFile({
        path: encodeForFilepath(path),
        directory: Directory.Data
      })
      return data as string
    } catch (err) {
      return 'No Data'
    }
  }

  async loadAsDataUrl(id: string): Promise<string | null> {
    try {
      const result: GetUriResult = await Filesystem.getUri({
        path: encodeForFilepath(id),
        directory: Directory.Data
      })
      return result.uri
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err.message)
      return null
    }
  }

  async save(path: string, data: Blob): Promise<void> {
    const base64 = await this.blobToBase64(data)

    try {
      await Filesystem.writeFile({
        path,
        directory: Directory.Data,
        data: base64,
        recursive: true
      })
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error('Error during native file save: ', err.message)
    }
  }

  async open(path: string): Promise<void> {
    const mimeType = this.getMimeTypeFromPath(path)
    const { uri } = await Filesystem.getUri({ path, directory: Directory.Data })

    window.cordova.plugins.fileOpener2.open(uri, mimeType, {
      error(error: { status: number; message: string }) {
        // eslint-disable-next-line no-console
        console.error(
          'Error during native file open: ',
          error.message,
          error.status
        )
      }
    })
  }

  async has(id: string): Promise<boolean> {
    try {
      const result: StatResult = await Filesystem.stat({
        path: encodeForFilepath(id),
        directory: Directory.Data
      })
      return !!result.uri
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.log(err.message)
      return false
    }
  }

  delete(path: string): Promise<void> {
    try {
      return Filesystem.deleteFile({ path, directory: Directory.Data })
    } catch (err: any) {
      return Promise.reject(err.message)
    }
  }

  async getFileNames(path: string): Promise<string[]> {
    const { files } = await Filesystem.readdir({
      path,
      directory: Directory.Data
    })

    return files.map((file) => file.name)
  }

  clear() {
    Filesystem.readdir({
      path: '',
      directory: Directory.Data
    })
      .then(({ files }: ReaddirResult) => {
        files.forEach((file: FileInfo) => {
          Filesystem.deleteFile({
            path: file.name,
            directory: Directory.Data
          })
        })
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err.message)
      })
  }

  private async blobToBase64(blob: Blob): Promise<string> {
    const arrayBuffer = await blob.arrayBuffer()
    return this.arrayBufferToBase64(arrayBuffer)
  }

  // @source: https://github.com/diachedelic/capacitor-blob-writer/blob/master/blob_writer.js#L7
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer)
    let binaryString = ''
    let byteNr = 0

    while (true) {
      if (byteNr >= bytes.byteLength) {
        break
      }
      binaryString += String.fromCharCode(bytes[byteNr])
      byteNr += 1
    }

    return window.btoa(binaryString)
  }

  private getMimeTypeFromPath(path: string) {
    const extension = path.split('.').pop() || ''
    return FileStorageApi.getMimeTypeFromExtension(extension)
  }

  private static getMimeTypeFromExtension(ext: string) {
    switch (ext.toLowerCase()) {
      case 'pdf':
        return 'application/pdf'

      case 'jpg':
      case 'jpeg':
        return 'image/jpeg'

      case 'png':
        return 'image/png'

      case 'svg':
        return 'image/svg+xml'

      default:
        return 'application/octet-stream'
    }
  }
}
