import { App } from '@capacitor/app'
import { Plugin } from '@nuxt/types'
import { navigateValidDeepLink } from '~/lib/url/deepLink'

const DeepLinkPlugin: Plugin = ({ $platform, app }) => {
  if (!$platform.isNative) return

  // Listen to URL when app is already open
  App.addListener('appUrlOpen', ({ url }) => {
    if (url.indexOf('https://') !== 0) return // Ignore non-https links
    navigateValidDeepLink(app, url)
  })
}

export default DeepLinkPlugin
