export enum Feature {
  PLATE_SCAN = 'plateScan'
}

export type Announcement = {
  key: Feature
  title: string
  descriptions: string[]
  icon: string
  images: string[]
}

export const ANNOUNCEMENTS: Announcement[] = [
  {
    key: Feature.PLATE_SCAN,
    title: 'announcement.plate_scan.title',
    descriptions: [
      'announcement.plate_scan.explanation',
      'announcement.plate_scan.instruction',
      'announcement.plate_scan.call_to_action'
    ],
    icon: 'svg/nfc',
    images: ['img/announcement/plate-scan.svg']
  }
]
