enum InboxMessageType {
  NEW_DEVICE,
  ORG_INVITE
}
type InboxMessageTypeString = keyof typeof InboxMessageType

enum OrganizationInvitationStatus {
  PENDING,
  ACCEPTED,
  DECLINED,
  EXPIRED
}
type OrganizationInvitationStatusString =
  keyof typeof OrganizationInvitationStatus

type OrganizationInvitationAction = 'ACCEPT' | 'DECLINE'

type NewDeviceAddedInboxMessageDto = {
  id: string
  title: string
  subtitle?: string
  description?: string
  read: boolean
  createdAt: string
  updatedAt: string
  messageType: 'NEW_DEVICE'
  orgId: string
  orgName?: string
  thingId?: string
  ref?: string
  sn?: string
  __typename?: string
}

type OrganizationInvitationInboxMessageDto = {
  id: string
  title: string
  subtitle?: string
  description?: string
  read: boolean
  createdAt: string
  updatedAt: string
  messageType: 'ORG_INVITE'
  userId: string
  inviterOrgId: string
  inviterOrgName?: string
  inviterOrgAddress?: string
  inviterEmail: string
  inviteeEmail: string
  status: OrganizationInvitationStatusString
  __typename?: string
}

type InboxMessageDto =
  | NewDeviceAddedInboxMessageDto
  | OrganizationInvitationInboxMessageDto

function assertNever(x: never): never {
  throw new Error('Unexpected type: ' + typeof x)
}

class InboxMessage {
  public readonly id: string
  public readonly title: string
  public readonly subtitle: string
  public readonly description: string
  public readonly read: boolean
  public readonly createdAt: string
  public readonly updatedAt: string
  public readonly messageType: InboxMessageTypeString

  constructor(dto: InboxMessageDto) {
    this.id = dto.id
    this.title = dto.title
    this.subtitle = dto.subtitle || ''
    this.description = dto.description || ''
    this.read = dto.read
    this.createdAt = dto.createdAt
    this.updatedAt = dto.updatedAt
    this.messageType = dto.messageType
  }

  static fromObject(dto: InboxMessageDto) {
    switch (dto.messageType) {
      case 'NEW_DEVICE':
        return new NewDeviceAddedInboxMessage(dto)
      case 'ORG_INVITE':
        return new OrganizationInvitationInboxMessage(dto)
      default:
        assertNever(dto)
    }
  }
}

class NewDeviceAddedInboxMessage extends InboxMessage {
  public readonly orgId: string
  public readonly orgName: string
  public readonly thingId: string
  public readonly ref: string
  public readonly sn: string

  constructor(dto: NewDeviceAddedInboxMessageDto) {
    super(dto)
    this.orgId = dto.orgId || ''
    this.orgName = dto.orgName || ''
    this.thingId = dto.thingId || ''
    this.ref = dto.ref || ''
    this.sn = dto.sn || ''
  }
}

class OrganizationInvitationInboxMessage extends InboxMessage {
  public readonly userId: string
  public readonly inviterOrgId: string
  public readonly inviterOrgName: string
  public readonly inviterOrgAddress: string
  public readonly inviterEmail: string
  public readonly inviteeEmail: string
  public readonly status: OrganizationInvitationStatusString

  constructor(dto: OrganizationInvitationInboxMessageDto) {
    super(dto)
    this.userId = dto.userId
    this.inviterOrgId = dto.inviterOrgId
    this.inviterOrgName = dto.inviterOrgName || ''
    this.inviterOrgAddress = dto.inviterOrgAddress || ''
    this.inviterEmail = dto.inviterEmail
    this.inviteeEmail = dto.inviteeEmail
    this.status = dto.status
  }

  private isActionable(status: OrganizationInvitationStatus): boolean {
    return !!this.status && OrganizationInvitationStatus[this.status] === status
  }

  public get isPending(): boolean {
    return this.isActionable(OrganizationInvitationStatus.PENDING)
  }

  public get isAccepted(): boolean {
    return this.isActionable(OrganizationInvitationStatus.ACCEPTED)
  }

  public get isDeclined(): boolean {
    return this.isActionable(OrganizationInvitationStatus.DECLINED)
  }

  public get isExpired(): boolean {
    return this.isActionable(OrganizationInvitationStatus.EXPIRED)
  }

  static get acceptAction(): OrganizationInvitationAction {
    return 'ACCEPT'
  }

  static get declineAction(): OrganizationInvitationAction {
    return 'DECLINE'
  }
}

const inboxMessageClasses = [
  NewDeviceAddedInboxMessage,
  OrganizationInvitationInboxMessage
]

export {
  InboxMessageType,
  InboxMessageDto,
  NewDeviceAddedInboxMessageDto,
  OrganizationInvitationInboxMessageDto,
  InboxMessage,
  NewDeviceAddedInboxMessage,
  OrganizationInvitationInboxMessage,
  inboxMessageClasses
}
