import { NuxtAppOptions } from '@nuxt/types'
import { parseURL } from '~/plugins/url'

export const navigateValidDeepLink = (app: NuxtAppOptions, url: string) => {
  if (!app.router || !url) return

  // get pathname from url
  const { protocol, hostname, pathname } = parseURL(url)
  if (!pathname) return

  // check if path is valid
  const result = app.router.resolve(pathname)
  if (result.resolved.matched.length === 0) return

  app.$mixpanel?.trackDeepLinkEvent({
    Link: url,
    Protocol: protocol,
    Hostname: hostname,
    Pathname: pathname
  })
  app.router.push(pathname)
}
