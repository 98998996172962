import { GetterTree } from 'vuex'
import { IRootState } from '~/store'
import { DownloadsState } from '~/store/documents/downloads/state'
import { Download } from '~/models/document/Download'

export default {
  downloads: ({ downloads }) => downloads,
  isDownloaded:
    (_, { downloads }) =>
    (download: Download) => {
      return !!downloads.find((d: Download) => download.is(d))
    }
} as GetterTree<DownloadsState, IRootState>
