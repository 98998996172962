/* eslint-disable import/no-named-as-default-member */
/* eslint-disable camelcase */
import { Plugin } from '@nuxt/types'
import mixpanel, {
  OverridedMixpanel,
  RequestOptions,
  Callback
} from 'mixpanel-browser'
import {
  MixpanelEventName,
  MixpanelEventProperties,
  NavigateEventProperties,
  EngagementEventProperties,
  InteractEventProperties,
  DeepLinkEventProperties,
  PlateScanEventProperties,
  UserProperties
} from './event'

export interface IMixpanel extends OverridedMixpanel {
  track(
    eventName: MixpanelEventName,
    properties?: MixpanelEventProperties,
    optionsOrCallback?: RequestOptions,
    callback?: Callback
  ): Promise<void>
  trackDeepLinkEvent(properties: DeepLinkEventProperties): void
  trackEngagementEvent(properties: EngagementEventProperties): void
  trackInteractEvent(properties: InteractEventProperties): void
  trackLoginEvent(): Promise<void>
  trackNavigateEvent(properties: NavigateEventProperties): void
  identifyUser(userId: string, props: UserProperties): void
  resetUser(): void
}

const MixpanelPlugin: Plugin = ({ app, store }, inject) => {
  const isProduction = app.$envConfig.TYPE === 'production'
  const token = app.$envConfig.MIXPANEL_TOKEN

  if (!isProduction || !token) return

  mixpanel.init(token)

  /**
   * Wrapper function to promisify mixpanel.track
   */
  const track = (
    eventName: MixpanelEventName,
    properties?: MixpanelEventProperties,
    options?: RequestOptions,
    callback?: Callback
  ): Promise<void> => {
    return new Promise((resolve) => {
      mixpanel.track(eventName, properties, options, (response) => {
        callback?.(response)
        resolve()
      })
    })
  }

  /**
   * send deeplink event
   */
  const trackDeepLinkEvent = (properties: DeepLinkEventProperties): void => {
    track('DeepLink', properties)
  }

  /**
   * send engagement event
   */
  const trackEngagementEvent = (
    properties: EngagementEventProperties
  ): void => {
    track('Engagement', properties)
  }

  /**
   * send interact event
   */
  const trackInteractEvent = (properties: InteractEventProperties): void => {
    track('Interact', properties)
  }

  /**
   * send login event immediately
   */
  const trackLoginEvent = (): Promise<void> =>
    track('Login', undefined, { send_immediately: true })

  /**
   * send route event only when user is logged in
   */
  const trackNavigateEvent = (properties: NavigateEventProperties): void => {
    if (!store.getters['user/isLoggedIn']) return
    track('Navigate', properties)
  }

  const trackPlateScanEvent = (properties: PlateScanEventProperties): void => {
    track('PlateScan', properties)
  }

  const identifyUser = (userId: string, props: UserProperties): void => {
    mixpanel.identify(userId)
    mixpanel.people.set(props)
  }

  const resetUser = (): void => {
    mixpanel.reset()
  }

  inject('mixpanel', {
    ...mixpanel,
    track,
    trackDeepLinkEvent,
    trackEngagementEvent,
    trackLoginEvent,
    trackNavigateEvent,
    trackInteractEvent,
    trackPlateScanEvent,
    identifyUser,
    resetUser
  })
}

export default MixpanelPlugin
