import { render, staticRenderFns } from "./Modal.vue?vue&type=template&id=42bee619&scoped=true"
import script from "./Modal.vue?vue&type=script&lang=js"
export * from "./Modal.vue?vue&type=script&lang=js"
import style0 from "./Modal.vue?vue&type=style&index=0&id=42bee619&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42bee619",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesButton: require('/builds/duerrdental/vistor/frontend/vsm/components/molecules/Button.vue').default})
