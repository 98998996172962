import { GetterTree } from 'vuex'
import { IRootState } from '~/store'
import { DocumentsState } from '~/store/documents/state'
import { Document } from '~/models/document/Document'

export default {
  documents:
    ({ documents }) =>
    (referenceId: string) =>
      documents[referenceId],
  documentsByCategoryId:
    (_, { documents }) =>
    (categoryId: string, referenceId: string) => {
      return documents(referenceId).filter(
        ({ category }: Document) => category === categoryId
      )
    },
  categories:
    ({ categories }) =>
    (referenceId: string) =>
      categories[referenceId]
} as GetterTree<DocumentsState, IRootState>
