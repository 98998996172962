import { isString } from 'lodash-es'
import { colorMap, IColorMap } from '~/lib/utils/color-map'

export const stringToColor = (string: string): string => {
  const firstCharacter = getFirstCharacter(string)

  if (isString(firstCharacter)) {
    return (colorMap as IColorMap)[firstCharacter]
  }

  return colorMap.default
}

const getFirstCharacter = (string: string): string | null => {
  if (!isString(string) || string.length === 0) {
    return null
  }

  return string[0].toLowerCase()
}

export const toLowerCase = (string: string): string => {
  if (isString(string)) {
    return string.toLowerCase()
  }

  return ''
}

export const isULID = (string: string): boolean => {
  const ulidRegex = /^[0-7][0-9a-hjkmnp-tv-zA-HJKMNP-TV-Z]{25}$/
  return ulidRegex.test(string)
}

/**
 * Checks if the given string is a valid device identifier.
 *
 * A valid device identifier is either a valid HIBC (Health Industry Bar Code)
 * or a valid serial number/reference.
 *
 * @param {string} string - The string to be validated as a device identifier.
 * @returns {boolean} - Returns true if the string is a valid device identifier, otherwise false.
 */
export const isDeviceIdentifier = (string: string): boolean => {
  const HIBC_REGEX =
    /^\+(E247|D678)([A-Z0-9]{4,})\/\${1,2}\+?7?([a-zA-z]{1,2}\d{4,})\/.*$/gm
  const SERIAL_REF_REGEX = /^[A-Za-z0-9-]+$/

  return HIBC_REGEX.test(string) || SERIAL_REF_REGEX.test(string)
}

export const isPlateIdentifier = (string: string): boolean => {
  return /^\+(E247|D678)([A-Z0-9]{4,})\/\$\+([0-9]{8}).*$/gm.test(string)
}
