import { Context } from '@nuxt/types'
import { Feature, ANNOUNCEMENTS } from './constants'
import type { Announcement } from './constants'
import Storage from '~/lib/storage'

const STORAGE_KEY = 'announcement-interacted'

const announcement = (ctx: Context) => {
  const interacted: Record<Feature, number> = Storage.getItem(STORAGE_KEY) || {}
  const isInteracted = (key: Feature): boolean => {
    return interacted[key] > 0
  }
  const markInteracted = () => {
    ANNOUNCEMENTS.forEach(({ key }) => {
      if (isInteracted(key)) return
      Object.assign(interacted, { [key]: 1 })
      ctx.$mixpanel?.trackEngagementEvent({
        Feature: key,
        Type: 'Announcement'
      })
    })
    Storage.setItem(STORAGE_KEY, interacted)
  }
  const getNew = (): Announcement[] => {
    return ANNOUNCEMENTS.filter(
      (announcement) => !isInteracted(announcement.key)
    )
  }
  return {
    markInteracted,
    getNew
  }
}

export default announcement
