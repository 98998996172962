import { GetterTree } from 'vuex'
import { sortBy } from 'lodash-es'
import { IRootState } from '~/store'
import { FavoritesState } from '~/store/documents/favorites/state'
import { Favorite } from '~/models/document/Favorite'
import { Product } from '~/models/product/Product'

export default {
  favorites: ({ favorites }) => {
    return sortBy(favorites, [(favorite: Favorite) => favorite.document.text])
  },
  isBookmarked:
    (_, { favorites }) =>
    (favorite: Favorite) => {
      return !!favorites.find(favorite.is.bind(favorite))
    },
  hasFavorites: (_, { favorites }) => favorites.length > 0,
  byProduct:
    (_, { favorites }) =>
    (product: Product) => {
      return favorites.filter((favorite: Favorite) =>
        favorite.hasProduct(product)
      )
    }
} as GetterTree<FavoritesState, IRootState>
