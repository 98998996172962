import { Context } from '@nuxt/types'
import { Feature } from './constants'
import Storage from '~/lib/storage'

const STORAGE_KEY = 'feature-interacted'

const feature = (ctx: Context) => {
  const interacted: Record<Feature, number> = Storage.getItem(STORAGE_KEY) || {}
  const isInteracted = (key: Feature): boolean => {
    return interacted[key] > 0
  }
  const markInteracted = (key: Feature) => {
    if (isInteracted(key)) return
    Object.assign(interacted, { [key]: 1 })
    Storage.setItem(STORAGE_KEY, interacted)
    ctx.$mixpanel?.trackEngagementEvent({
      Feature: key,
      Type: 'Feature'
    })
  }

  return { isInteracted, markInteracted }
}

export default feature
