import { Plugin } from '@nuxt/types'
import feature from './feature'
import announcement from './announcement'

const engagement: Plugin = (ctx, inject) => {
  inject('engagement', {
    feature: feature(ctx),
    announcement: announcement(ctx)
  })
}

export default engagement
